import {TextArea} from "../TextArea";
import React from "react";
import styled from "styled-components";
import {colors, textSizes} from "../../styles/settings";

const Flag = ({flagColor, flagDescription}) => (
  <FlagContainer flagColor={flagColor}>
    <FlagIndicator flagColor={flagColor}>
      <div></div>
    </FlagIndicator>
    <span>{flagDescription}</span>
  </FlagContainer>
);

const FlagIndicator = styled.div`
  border: solid 2px ${({ flagColor }) =>
  flagColor === '' ?
    'transparent'
    :
    flagColor === 'red' ?
      colors.ninoRed
      :
      flagColor === 'green' ?
        colors.ninoGreen
        :
        colors.ninoYellow
  };
  opacity: 0.5;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 30px;
    height: 30px;
    background-color: ${({ flagColor }) =>
      flagColor === '' ?
        'transparent'
        :
        flagColor === 'red' ?
          colors.ninoRed
          :
          flagColor === 'green' ?
            colors.ninoGreen
            :
            colors.ninoYellow
    };
    //background-color: red;
    opacity: 0.3;
    border-radius: 100px;
  }
`;

const FlagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  > span {
    margin-left: 10px;
    font-size: ${textSizes.medium};
    color: ${({ flagColor }) =>
      flagColor === '' ?
        'transparent'
        :
        flagColor === 'red' ?
          colors.ninoRed
          :
          flagColor === 'green' ?
            colors.ninoGreen
            :
            colors.ninoYellow
    };
  }
`;

export {Flag};