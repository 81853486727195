import React from 'react';
import logo from './logo.svg';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import './App.css';
import axios from 'axios';
//import Api from './Config/Api';
import GlobalStyle from './styles/global';
import { ButtonContainer, Button } from './styles/generalStyles'
import { Home, ApiTest } from './Pages'

function App() {
  return (
    <BrowserRouter className="App">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/api" component={ApiTest} />
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
    /*<div className="App">

      <ButtonContainer>
        <Button onClick={() => sendToAPi()}>
          <span>Send</span>
        </Button>
      </ButtonContainer>
      <GlobalStyle/>
    </div>*/
  );
}
export default App;
