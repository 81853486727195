import React, {useEffect} from 'react';
import {Header, AboutUs, TryApi} from "../Components";

const Home = ({history}) => {

  useEffect(() => {
    //console.log(history)
  }, []);
  return (
    <>
      <Header history={history}/>
      <AboutUs/>
      <TryApi history={history}/>
    </>
  );
}

export {Home};