import React, {useEffect} from 'react';
import styled from "styled-components";
import {colors, screenSizes, textSizes} from '../styles/settings'
import Logo from '../assets/logo.svg';
import { MdMenu } from "react-icons/md";


const Header  = ({history}) => {
  return (
    <Container>
      <LogoContainer onClick={() => {history.push('/')}}>
        <img src={Logo} alt={''}/>
      </LogoContainer>
      {/*<IconWrapper>
        <MdMenu style={{fontSize: '32px', color: `${colors.ninoBlue}`}}  />
      </IconWrapper>*/}
      <Links>
        <span onClick={() => {history.push('/')}}>Home</span>
        {/*<span>About Us</span>
        <span>Features</span>
        <span>Our Team</span>*/}
        <span onClick={() => history.push("/api")}>Free trial</span>
      </Links>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
  align-items: center;
  @media (${screenSizes.phone}) {
    height: 10vh;
    justify-content: space-between;
    padding: 18px;
  }
`;

const LogoContainer = styled.div`
  //width: 40vw;
  cursor: pointer;
  img {
    width: 102.86px;
    height: 130px;
  }  
  @media (${screenSizes.phone}) {
    img {
      width: 10vh;
      height: 12vh;
    }
  }
`;

const Links = styled.div`
  //min-width: 500px;
  min-width: 160px;
  //height: 15vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    cursor: pointer;
    font-size: ${textSizes.medium};
    &:hover {
      font-weight: bold;
      font-size: ${textSizes.large};
      color: ${colors.ninoBlue};
      animation: animationSpan 300ms 1;
      @keyframes animationSpan {
        0% {
          
          transform: scale(1);
        } 
        50% {
          //font-size: ${textSizes.large};
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }
  @media (${screenSizes.phone}) {
    display: none;
  }
  @media (${screenSizes.tablet}) {
    display: none;
  }
  @media (${screenSizes.desktop}) {
    display: flex;
  }
`;

const IconWrapper = styled.div`
  @media (${screenSizes.phone}) {
    display: flex;
  }
  @media (${screenSizes.tablet}) {
    display: flex;
  }
  @media (${screenSizes.desktop}) {
    display: none;
  }
`;
export {Header};
