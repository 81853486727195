import React from 'react';
import styled from "styled-components";
import {screenSizes, textSizes, colors} from '../styles/settings'
import Image from '../assets/children_playing_videogames.jpg'

const AboutUs = () => {

  return (
    <Container>
      <ImageContainer>
        <img src={Image} alt={''}/>
      </ImageContainer>
      <TextContainer>
        <Title>About Us</Title>
        <Text>We are a startup that develops artificial intelligence focused on reducing cyber crimes for vulnerable people.
          We value Trust and Credibility, through sustainable results, basing our work on respect and dedication.</Text>
      </TextContainer>
    </Container>
  );
}

export {AboutUs};

const Container = styled.div`
  width: 100vw;
  //height: 40vw;
  //background-color: rgba(56, 132, 197, 0.2);
  background-color: rgba(243, 149, 43, 0.3);
  //background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0 10vw;
  align-items: center;
  flex-direction: column;
  @media (${screenSizes.phone}) {
    //height: 10vh;
    justify-content: flex-start;
    padding: 18px;
  }
  @media (${screenSizes.desktop}) {
    //height: 40vw;
    flex-direction: row;
    /*padding-top: 30px;
    padding-bottom: 30px;*/
    height: 600px;
   }
`;

const ImageContainer = styled.div`
  //width: 40vw;
  animation: childrenImage 1s 1;
  img {
    width: 35em;
    height: 28em;
  }  
  @media (${screenSizes.phone}) {
    img {
      width: 80vw;
      height: 60vw;
    }
  }
  @media (${screenSizes.tablet}) {
    img {
      width: 70vw;
      height: 52.5vw;
    }
    margin-top: 30px;
    margin-bottom: 0;
  }
  @media (${screenSizes.desktop}) {
    img {
      width: 35em;
      height: 28em;
    }  
    position: relative;
    @keyframes childrenImage {
      0% {
        opacity: 0;
        
        left: -40px;
        //transform: scale(0);
      } 
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
        
        left: 0;
       //transform: scale(1);
    }
  
}
`;

const TextContainer = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: textContainerAnimation 1s 1;
  @media (${screenSizes.phone}) {
    //display: none;
    margin-top: 20px;
  }
  @media (${screenSizes.desktop}) {
    min-width: 300px;
    height: 500px;
  }
  @media (${screenSizes.tablet}) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  position: relative;
  @keyframes textContainerAnimation {
      0% {
        opacity: 0;
        left: 40px;
        //transform: scale(0);
      } 
      50% {
        opacity: 1;
      }
      100% {
        left: 0;
      }
        //transform: scale(1);
    }
`;

const Title = styled.span`
  font-size: ${textSizes.extraLarge};
  margin-bottom: 20px;
  text-align: left;
  width: 60%;
  //color: rgba(56, 132, 197, 1);
  //color: #2077bf;
  font-weight: 600;
  
  @media (${screenSizes.phone}) {
    font-size: ${textSizes.large};
    width: 90%;
  }
  @media (${screenSizes.tablet}) {
    width: 80%;
  }
  
  @media (${screenSizes.desktop}) {
    width: 60%;
  }
`;

const Text = styled.span`

  width: 60%;
  //height: 105px;
  font-size: ${textSizes.large};
  text-align: left;
  @media (${screenSizes.phone}) {
    font-size: ${textSizes.medium};
    width: 90%;
  }
  @media (${screenSizes.tablet}) {
    width: 80%;
  }
  
  @media (${screenSizes.desktop}) {
    width: 60%;
  }
`;




