//try our api for free

import React from "react";
import styled from "styled-components";
import {colors, screenSizes, textSizes} from '../../styles/settings'
import {Button, ButtonContainer} from "../../styles/generalStyles";


const TryApi = ({history}) => (
  <>
    <ButtonContainer style={{paddingTop: '30px', paddingBottom: '30px', backgroundColor: 'rgba(32, 119, 191, 0.2)'}}>
      <Button onClick={() => history.push('/api')}>
        <span>Free trial</span>
      </Button>
    </ButtonContainer>
  </>
);

export {TryApi};