export const screenSizes = {
  iPhone5: 'max-width: 330px',
  phone: 'max-width: 599px',
  tablet: 'min-width: 600px',
  desktop: 'min-width: 900px',
};

export const textSizes = {
  smallest: '0.625rem', // 10px
  extraSmall: '0.75rem', // 12px
  small: '0.875rem', // 14px
  normal: '1rem', // 16px
  medium: '1.125rem', // 18px
  large: '1.5rem', // 24px
  extraLarge: '2.250rem', // 36px
  largest: '3rem', // 48px
};

export const colors = {
  primary: '#4a54df',
  secondary: '#15d4d8',
  lightest: '#ffffff',
  lightestGrey: '#f2f2f2',
  lightGrey: '#ededed',
  grey: '#dddddd',
  middleGrey: '#9d9d9d',
  darkGrey: '#707070',
  darkest: '#484848',
  tomato: '#ed5151',
  blue: '#2f93db',
  ninoYellow: '#f3952b',
  ninoBlue: '#2077bf',
  ninoRed: '#C23B38',
  ninoGreen: '#4EAD8E',
};