import {create} from 'apisauce';

const BASE_URL = 'https://nino-270713.appspot.com';

const Api = create({
  baseURL: `${BASE_URL}`,

  timeout: 30000,
});

/*Api.addAsyncRequestTransform(request => async () => {
  //const token = 'aaaa';
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
});

Api.addResponseTransform(response => {
  if (!response.ok) {
    throw response;
  }
});*/

export default Api;
