import styled from "styled-components";
import {colors, screenSizes, textSizes} from "./settings";


export const ButtonContainer = styled.div`
  width: 100vw;
  //height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  }
`;

export const Button = styled.div`
  width: 220px;
  height: 48px;
  //padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  //background-color: #61dafb;
  background-color: ${colors.ninoBlue};
  border-radius: 30px;
  cursor: pointer;
  > span {
    font-size: ${textSizes.large};
    color: #ffffff;
    font-weight: bold;
  }
  &:active {
    opacity: 1;
    background-color: ${colors.grey};
  }
  &:hover {
    opacity: 0.7;
  }
  @media (${screenSizes.phone}) {
    //padding: 10px 20px;
  }
  animation: buttonAnimation 1s 1;
  @keyframes buttonAnimation {
    0% {
      opacity: 1;
      transform: scale(1);
    } 
    50% {
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const Text = styled.span`

  width: 60%;
  //height: 105px;
  font-size: ${textSizes.large};
  text-align: left;
  @media (${screenSizes.phone}) {
    font-size: ${textSizes.medium};
    width: 90%;
  }
  @media (${screenSizes.tablet}) {
    width: 80%;
  }
  
  @media (${screenSizes.desktop}) {
    width: 60%;
  }
`;