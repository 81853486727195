import React from "react"
import PropTypes from "prop-types"
import {colors, screenSizes} from "../../styles/settings"
import './styled.css'
import styled from "styled-components";

const TextArea = ({placeholder, height, paddingBottom, type, rows, name, onChange}) => {
  return(
    <TextAreaWrapper>
      <textarea
        name={name}
        className="textarea"
        onChange={onChange}
        placeholder={placeholder}
        style={{
          //fontFamily: 'Roboto',
          /*color: 'black',*/
          //color: 'rgb(32, 119, 191)',
          color: '#4EAD8E',
          //fontSize: '15px',
          fontSize: '1rem',
          letterSpacing: '1px',
          lineHeight: '30px',
          textAlign:' center',
          paddingTop: '15px',
          padding: '15px',
          paddingBottom: (paddingBottom != null ? `${paddingBottom}px` : '0px'),
          alignItems: 'center',
          justifyContent: 'center',
          /*backgroundColor: 'rgba(32, 119, 191, 0.2)',*/
          backgroundColor: 'rgba(78, 173, 142, 0.1)',
          /*borderTopLeftRadius: '20px',
          borderBottomRightRadius: '20px',*/
          borderRadius: '20px',

          height: `${height}px`,
          width: '100%',
        }}
      />
    </TextAreaWrapper>
  )
}

const TextAreaWrapper = styled.div`
  width: 70%;
  @media (${screenSizes.phone}) {
    width: 90vw;
  }
`;

TextArea.propTypes = {
  placeholder: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  paddingTop: PropTypes.number,
  rows: PropTypes.number,
  type: PropTypes.string,
}

export {TextArea};