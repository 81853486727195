import React, {useState, useEffect} from 'react';
import {Header, AboutUs, TextArea, Flag} from "../Components";
import styled from "styled-components";
import {colors, screenSizes, textSizes} from '../styles/settings'
import axios from 'axios';
import Api from '../Config/Api';
import Loader from 'react-loader-spinner'
import { ButtonContainer, Button, Text } from '../styles/generalStyles'

const ApiTest = ({history}) => {
  const [phrase, setPhrase] = useState('');
  const [response, setResponse] = useState(null);
  const [flagColor, setFlagColor] = useState('');
  const [flagDescription, setFlagDescription] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(response)
    if(response !== null) {
      if(response < 20) {
        setFlagColor('green')
        setFlagDescription('This is an innocent conversation.')
        //console.log('aqui')
      }
      else if(response < 45) {
        setFlagColor('yellow')
        setFlagDescription('This conversation requires attention.')
      }
      else if(response > 45) {
        setFlagColor('red')
        setFlagDescription('This is a dangerous conversation.')
      }
    }
  }, [response]);

  const sendToAPi = (e) => {
    async function loadData() {
      setLoading(true);
      const response = await Api.post(
        '/prediction',
        {
          "phrases":	[`${phrase}`],
        },{
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
        .then(res => {
          console.log(res.data[0])
          //console.log(res)
          setResponse(res.data[0])
          setLoading(false);
        })
        .catch(error => {
          //console.error(error);
          setLoading(false);
        });
    }
    if(phrase !== '') {
      loadData();
      //console.log('phrase', phrase)
    }
  };
  return (
    <>
      <Header history={history}/>
      <Container>
        <Text>Imagine that you are talking to a child that you are used to playing, send a message to him</Text>
        <TextArea
          rows={5}
          name={"phrase"}
          type={"text"}
          paddingBottom={105}
          height={200}
          placeholder={"Type a conversation"}
          //onChange={() => {setPhrase()}}
          onChange={(e) => setPhrase(e.target.value)}
        />
        {loading ?
          <Loader
            type="ThreeDots"
            style={{marginTop: '17px'}}
            color="#4EAD8E"
            height={32}
            width={50}
            timeout={3000} //3 secs
          />
        :
          <Flag flagColor={flagColor} flagDescription={flagDescription}/>
        }
      </Container>
      <ButtonContainer>
        <Button onClick={() => sendToAPi()}>
          <span>Send</span>
        </Button>
      </ButtonContainer>
    </>
  );
}

const Container = styled.div`
  //background-color: rgba(78, 173, 142, 0.5);
  display: flex;
  flex-direction: column;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.5rem;
    color: ${colors.ninoBlue};
    @media (${screenSizes.phone}) {
      font-size: 1rem;
    }
  }
`;

export {ApiTest};